import React, { useEffect, useState, useRef } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import DynamicDataTables from '../commonComponent/DataTable/DynamicDataTable';
import { URL_Start } from '../../../constant';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { Spinner } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ViewToolTip from '../UI/ToolTips/ViewToolTip';
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';

const DoctorBusinessMappingMaster = () => {
    let textSearch = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [DrBusinessMaplistCSV, setDrBusinessMaplistCSV] = useState([]);
    const [downloadvisible, setDownloadVisible] = useState(false);
    const [PageHelper, SetPageHelper] = useState({
        PageNo: '1',
        PageSize: '10',
        TotalRecord: 0,
        dsDrBusinessMapList: []

    });

    useEffect(() => {
        BindGrid(1, 10, '');
    }, []);

    const handleSearch = async () => {

        BindGrid(1, 10, textSearch.current.value.toLowerCase());

    }

    const BindGrid = async (PageNo, PageSize, SearchTxtInput) => {
        setIsLoading(true);
        try {

            let url = URL_Start + 'DoctorMaster/getDrBusinessMappingMaster';
            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: { PageNo: `${PageNo}`, PageSize: `${PageSize}`, SearchTxt: `${SearchTxtInput}` }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                // let data = await response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null) {

                        await SetPageHelper({
                            ...PageHelper,
                            PageNo: Number(PageNo),
                            PageSize: Number(PageSize),
                            TotalRecord: Number(ds.TotalRecord),
                            dsDrBusinessMapList: ds.ObjBindDrBusinessMapList == null ? [] : ds.ObjBindDrBusinessMapList
                        });
                        setIsLoading(false);
                    }
                     else{
                        await SetPageHelper({
                            ...PageHelper,
                            PageNo: Number(1),
                            PageSize: Number(1),
                            TotalRecord: Number(1),
                            dsDrBusinessMapList:[]
                        });
                        setIsLoading(false);
                     }
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    console.log('no record found');
                    await SetPageHelper({
                        ...PageHelper,
                        PageNo: Number(1),
                        PageSize: Number(1),
                        TotalRecord: Number(1),
                        dsDrBusinessMapList:[]
                    });
                    setIsLoading(false);
                }

            }
            else { SweetAlert.fire('There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.'); setIsLoading(false); }
            // return data;
        } catch (error) {
            if (error.response) {
                ErrorFunction(error.response.status);
                await SetPageHelper({
                    ...PageHelper,
                    PageNo: Number(1),
                    PageSize: Number(1),
                    TotalRecord: Number(1),
                    dsDrBusinessMapList:[]
                });
                setIsLoading(false);
                setIsLoading(false);
            }
        }
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }

    const paginationOptions = {

        totalSize: PageHelper.TotalRecord,
        sizePerPage: PageHelper.PageSize,
        showTotal: true,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',

        onSizePerPageChange: (sizePerPage, page, event) => {
            console.log('Size per page change!!!');
            console.log('Newest size per page:' + sizePerPage);
            console.log('Newest page:' + page);
            BindGrid(page, sizePerPage, textSearch.current.value.toLowerCase());
            document.getElementsByClassName('dropdown-menu show')[0].classList.remove('show')
        },
        onPageChange: (page, sizePerPage) => {
            console.log('Page change!!!');
            console.log('Newest size per page:' + sizePerPage);
            console.log('Newest page:' + page);
            BindGrid(page, sizePerPage, textSearch.current.value.toLowerCase());
        }
    };

    const columns = [{
        dataField: '#',
        text: '#',
        formatter: (cell, row, rowIndex) => {
            let rowNumber = (PageHelper.PageNo - 1) * PageHelper.PageSize + (rowIndex + 1);
            return <span>{rowNumber}</span>;
        },
        headerStyle: (colum, colIndex) => {
            return { width: '5%' };
        }

    },
    {
        dataField: 'DrUnquieId',
        text: 'DrUniqueId',
        editable: false

    },
    {
        dataField: 'DoctorName',
        text: 'DoctorName',
        editable: false

    },
    {
        dataField: 'State',
        text: 'State',
        editable: false

    },
    {
        dataField: 'Region',
        text: 'Region',
        editable: false

    },
    ,
    {
        dataField: 'District',
        text: 'District',
        editable: false

    },
    {
        dataField: 'Modality1Name',
        text: 'Modality1 Name',
        editable: false
    },
    {
        dataField: 'Modality2Name',
        text: 'Modality2 Name',
        editable: false
    }
    ,
    {
        dataField: 'Modality3Name',
        text: 'Modality3 Name',
        editable: false
    },
    {
        dataField: 'Modality1SalesPersonEmpCode',
        text: 'Modality1SalesPerson EmpCode',
        editable: false
    },
    {
        dataField: 'Modality1SalesPerson',
        text: 'Modality1 SalesPerson',
        editable: false
    },
    {
        dataField: 'Modality1SalesManagerEmpCode',
        text: 'Modality1SalesManager EmpCode',
        editable: false
    },
    
    {
        dataField: 'Modality1SalesManager',
        text: 'Modality1 SalesManager',
        editable: false
    },
    {
        dataField: 'Modality1SalesHeadEmpCode',
        text: 'Modality1SalesHead EmpCode',
        editable: false
    },
    {
        dataField: 'Modality1SalesHead',
        text: 'Modality1 SalesHead',
        editable: false
    },
    {
        dataField: 'Modality2SaleEmployeeEmpCode',
        text: 'Modality2SaleEmployee EmpCode',
        editable: false
    },
    {
        dataField: 'Modality2SalesPerson',
        text: 'Modality2 SalesPerson',
        editable: false
    },
    {
        dataField: 'Modality2SalesManagerEmpCode',
        text: 'Modality2SalesManager EmpCode',
        editable: false
    },
    {
        dataField: 'Modality2SalesManager',
        text: 'Modality2 SalesManager',
        editable: false
    },
    {
        dataField: 'Modality2SalesHeadEmpCode',
        text: 'Modality2SalesHead EmpCode',
        editable: false
    },
    {
        dataField: 'Modality2SalesHead',
        text: 'Modality2 SalesHead',
        editable: false
    },

    {
        dataField: 'Modality3SaleEmployeeEmpCode',
        text: 'Modality3SaleEmployee EmpCode',
        editable: false
    },
    {
        dataField: 'Modality3SalesPerson',
        text: 'Modality3 SalesPerson',
        editable: false
    },
    {
        dataField: 'Modality3SalesManagerEmpCode',
        text: 'Modality3SalesManager EmpCode',
        editable: false
    },
    {
        dataField: 'Modality3SalesManager',
        text: 'Modality3 SalesManager',
        editable: false
    },
    {
        dataField: 'Modality3SalesHeadEmpCode',
        text: 'Modality3SalesHead EmpCode',
        editable: false
    },
    {
        dataField: 'Modality3SalesHead',
        text: 'Modality3 SalesHead',
        editable: false
    }

    ];

    const handleSearchList = async () => {

        DownloadDrBusinessMapList(1, 10, textSearch.current.value.toLowerCase());

    }
    const DownloadDrBusinessMapList = async (PageNo, PageSize, SearchTxtInput) => {
        try {
         
                // setIsLoading(true);
                let url = URL_Start + 'DoctorMaster/getDrBusinessMappingMaster';
                let options = {
                    method: 'POST',
                    url: url,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json;charset=UTF-8'
                    },
                    data: { PageNo: `${PageNo}`, PageSize: `${PageSize}`, SearchTxt: `${SearchTxtInput}`, DrExcelStatus: '1' }
                };
                let response = await axios(options);
                let responseOK = response && response.status == 200;
                if (responseOK) {
                    let data = response.data;
                    if (data.Success) {
                        if (data.Data != null && data.Data.ObjBindDrBusinessMapList.length>0) { setDownloadVisible(true); setDrBusinessMaplistCSV(data.Data.ObjBindDrBusinessMapList); document.getElementById("csvdownload").click(); } else {
                            
                            
                            setDownloadVisible(false); setDrBusinessMaplistCSV([]); 
                            SweetAlert.fire({ title: "warning", text:'no record found', icon: "info" });
                        
                        }
                    }
                    else {
                        setDownloadVisible(false); setDrBusinessMaplistCSV([]);
                    }
                }
                else { 
                    setDownloadVisible(false); setDrBusinessMaplistCSV([]);
                    SweetAlert.fire('There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.');}
            // return data;
        } catch (error) {
            if (error.response) {
                setDownloadVisible(false); setDrBusinessMaplistCSV([]);
                ErrorFunction(error.response.status);
            }

        }
    }
    const APIError = async (ErrorCode, ErrorMsg) => {
        if (ErrorCode === "-1") { SweetAlert.fire({ title: "Data unavailable", text: "No record found", icon: "error" }) }
        else if (ErrorCode === "-2") { SweetAlert.fire({ title: "API Server Error", text: ErrorMsg, icon: "error" }); }
        else if (ErrorCode === "-3") { SweetAlert.fire({ title: "Runtime Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }
        else if (ErrorCode === "-4") { SweetAlert.fire({ title: "Wrong Credentials", text: "Please enter correct credentials", icon: "error" }) }
        else if (ErrorCode === "-5") { SweetAlert.fire({ title: "Oops", text: "Data could not be saved, Please try again later", icon: "error" }) }
        else if (ErrorCode === "-6") { SweetAlert.fire({ title: "Failed", text: "Check DB Logs", icon: "error" }) }
        else if (ErrorCode === "-7") { SweetAlert.fire({ title: "Duplicate", text: "Template already exists", icon: "error" }) }
        else if (ErrorCode === "-8") { SweetAlert.fire({ title: "Data unavailable", text: "No record found to import", icon: "error" }) }
        else if (ErrorCode === "-9") { SweetAlert.fire({ title: "LDAP authentication failed", text: ErrorMsg, icon: "error" }) }
        else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }) }
    }

    return (
        <React.Fragment>
            <Breadcrumb title="Doctor Business Mapping Master" parent="Master" />
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form.Row className='justify-content-center'>
                                    <Col lg={6} md={6} className="col-7">
                                        <Form.Group>

                                            <InputGroup className="mb-3">
                                                <Form.Control  placeholder="Doctor UnquieId OR Doctor Name OR Employee Code OR Modality " ref={textSearch} />
                                                <InputGroup.Append>
                                                    <Button onClick={handleSearch} variant="secondary" ><i className='fa fa-search'></i></Button>
                                                </InputGroup.Append>
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={2} md={6} className="col-5">
                                        <Form.Group>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Append>
                                                    <Button variant='primary' type='submit' onClick={handleSearchList} filename={"DoctorBusinessMapList.csv"} target="_blank" >Download CSV</Button>
                                                </InputGroup.Append>
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col md={12} className='text-center'>
                                        {/* <Button variant='secondary' className="mr-2" onClick={handleSearch} >Search</Button> */}

                                    </Col>
                                </Form.Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Header className='pb-0'>
                                <Card.Title>Doctor Business Mapping  List</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                 {
                                    isLoading &&
                                    <div className='text-center'> <Spinner animation="border" size='sm' /></div>
                                }
                                <DynamicDataTables keyField="DoctorId" tableData={PageHelper.dsDrBusinessMapList} columns={columns} pagination={paginationOptions ? paginationFactory(paginationOptions) : null} /> 
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {
                    downloadvisible &&
                    <CSVLink data={DrBusinessMaplistCSV} filename="DoctorBusinessMapList.csv" target="_blank" id="csvdownload"></CSVLink>
                }
            </Container>
        </React.Fragment>
    )
}

export default DoctorBusinessMappingMaster